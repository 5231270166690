import useUserSettings from '@asaprint/asap/hooks/useUserSettings.js';
import { OrderReceivedPhase } from '@asaprint/common/constants/OrderReceived.js';
import { Phases } from '@asaprint/common/constants/Phase.js';
import { PhaseGroups } from '@asaprint/common/constants/PhaseGroup.js';
import { isScheduledToday, isScheduledTomorrow } from '@asaprint/common/helpers/OrderReceived.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { alpha, Box, Button, ButtonGroup, buttonGroupClasses, SxProps, Theme } from '@mui/material';
import React, { useCallback } from 'react';

export interface Filter {
  graphicDesign: boolean;
  dataCheck: boolean;
  designApprove: boolean;
  bigFormatPrint: boolean;
  smallFormatPrint: boolean;
  shapedPrePlotter: boolean;
  withoutAsaprint: boolean;
  today: boolean;
  tomorrow: boolean;
}

export const initialFilter: Filter = {
  graphicDesign: false,
  dataCheck: false,
  designApprove: false,
  shapedPrePlotter: false,
  smallFormatPrint: false,
  bigFormatPrint: false,
  withoutAsaprint: false,
  today: false,
  tomorrow: false,
};

interface OwnProps {
  userSettingsKey: string;
  disableGraphicDesign?: boolean;
  disableDataCheck?: boolean;
  disableDesignApprove?: boolean;
  disableBigFormatPrint?: boolean;
  disableSmallFormatPrint?: boolean;
  disableShappedPrePlotter?: boolean;
  disableWithoutAsaprint?: boolean;
  disableToday?: boolean;
  disableTomorrow?: boolean;
  todayTasksCount: number;
  tomorrowTasksCount: number;
}

type Props = OwnProps;

const buttonGroupResponsive: SxProps = {
  mr: { xs: 0, md: 2 },
  mb: { xs: 2, md: 0 },
  flexDirection: { xs: 'column', md: 'row' },
  display: { xs: 'grid', md: 'inline-flex' },
  gap: { xs: '3px', md: 0 },
  gridTemplateColumns: 'auto auto',
  [`& button.${buttonGroupClasses.grouped}`]: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      borderRadius: `${theme.shape.borderRadius}px !important`,
      borderColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
    },
  }),
};

const TasksFilter: React.FunctionComponent<Props> = ({
  userSettingsKey,
  disableGraphicDesign = false,
  disableDataCheck = false,
  disableDesignApprove = false,
  disableBigFormatPrint = false,
  disableShappedPrePlotter = false,
  disableSmallFormatPrint = false,
  disableToday = false,
  disableTomorrow = false,
  disableWithoutAsaprint = true,
  todayTasksCount,
  tomorrowTasksCount,
}) => {
  const {
    filter,
    onGraphicDesignToggle,
    onDataCheckToggle,
    onDesignApproveToggle,
    onBigFormatPrintToggle,
    onSmallFormatPrintToggle,
    onShappedPrePlotterToggle,
    onWithoutAsaprintToggle,
    onTodayToggle,
    onTomorrowToggle,
  } = useTasksFilter(userSettingsKey);

  const hasPhaseFilter =
    !disableGraphicDesign ||
    !disableDataCheck ||
    !disableDesignApprove ||
    !disableBigFormatPrint ||
    !disableSmallFormatPrint ||
    !disableShappedPrePlotter;

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
    >
      {hasPhaseFilter && (
        <ButtonGroup sx={buttonGroupResponsive}>
          {!disableGraphicDesign && (
            <Button
              disableElevation
              color="primary"
              variant={filter.graphicDesign ? 'contained' : 'outlined'}
              onClick={onGraphicDesignToggle}
            >
              Grafický návrh
            </Button>
          )}
          {!disableDataCheck && (
            <Button
              disableElevation
              color="primary"
              variant={filter.dataCheck ? 'contained' : 'outlined'}
              onClick={onDataCheckToggle}
            >
              Kontrola dát
            </Button>
          )}
          {!disableDesignApprove && (
            <Button
              color="primary"
              disableElevation
              variant={filter.designApprove ? 'contained' : 'outlined'}
              onClick={onDesignApproveToggle}
            >
              Schvaľovanie návrhu
            </Button>
          )}
          {!disableBigFormatPrint && (
            <Button
              disableElevation
              color="primary"
              variant={filter.bigFormatPrint ? 'contained' : 'outlined'}
              onClick={onBigFormatPrintToggle}
            >
              Veľkoformátová tlač
            </Button>
          )}
          {!disableSmallFormatPrint && (
            <Button
              disableElevation
              color="primary"
              variant={filter.smallFormatPrint ? 'contained' : 'outlined'}
              onClick={onSmallFormatPrintToggle}
            >
              Maloformátová tlač
            </Button>
          )}
          {!disableShappedPrePlotter && (
            <Button
              disableElevation
              color="primary"
              variant={filter.shapedPrePlotter ? 'contained' : 'outlined'}
              onClick={onShappedPrePlotterToggle}
            >
              Tvarový predrez plotterom
            </Button>
          )}
        </ButtonGroup>
      )}{' '}
      {!disableWithoutAsaprint && (
        <Button
          disableElevation
          color="primary"
          variant={filter.withoutAsaprint ? 'contained' : 'outlined'}
          onClick={onWithoutAsaprintToggle}
          sx={{ mr: 2 }}
        >
          Bez ASAPRINTových
        </Button>
      )}{' '}
      {(!disableToday || !disableTomorrow) && (
        <ButtonGroup>
          {!disableToday && (
            <Button
              disableElevation
              color="primary"
              variant={filter.today ? 'contained' : 'outlined'}
              onClick={onTodayToggle}
            >
              Dnes ({todayTasksCount || 0})
            </Button>
          )}
          {!disableTomorrow && (
            <Button
              disableElevation
              color="primary"
              variant={filter.tomorrow ? 'contained' : 'outlined'}
              onClick={onTomorrowToggle}
            >
              Zajtra ({tomorrowTasksCount || 0})
            </Button>
          )}
        </ButtonGroup>
      )}
    </Box>
  );
};

TasksFilter.displayName = 'TasksFilter';

export default React.memo<Props>(TasksFilter);

export function useTasksFilter(userSettingsKey: string): {
  filter: Filter;
  onGraphicDesignToggle();
  onDataCheckToggle();
  onDesignApproveToggle();
  onBigFormatPrintToggle();
  onSmallFormatPrintToggle();
  onShappedPrePlotterToggle();
  onWithoutAsaprintToggle();
  onTodayToggle();
  onTomorrowToggle();
} {
  const [filter, setFilter] = useUserSettings<Filter>(userSettingsKey, {
    graphicDesign: false,
    dataCheck: false,
    designApprove: false,
    bigFormatPrint: false,
    smallFormatPrint: false,
    shapedPrePlotter: false,
    withoutAsaprint: false,
    today: false,
    tomorrow: false,
  });

  const onToggle = useCallback(
    (name: keyof Filter) => () => {
      setFilter((s) => ({
        ...s,
        [name]: !s[name],
      }));
    },
    [setFilter],
  );

  const onGraphicDesignToggle = useEventCallback(onToggle('graphicDesign'));
  const onDataCheckToggle = useEventCallback(onToggle('dataCheck'));
  const onDesignApproveToggle = useEventCallback(onToggle('designApprove'));
  const onBigFormatPrintToggle = useEventCallback(onToggle('bigFormatPrint'));
  const onSmallFormatPrintToggle = useEventCallback(onToggle('smallFormatPrint'));
  const onShappedPrePlotterToggle = useEventCallback(onToggle('shapedPrePlotter'));
  const onWithoutAsaprintToggle = useEventCallback(onToggle('withoutAsaprint'));
  const onTodayToggle = useEventCallback(() => {
    setFilter((s) => ({
      ...s,
      today: !s.today,
      tomorrow: false,
    }));
  });
  const onTomorrowToggle = useEventCallback(() => {
    setFilter((s) => ({
      ...s,
      tomorrow: !s.tomorrow,
      today: false,
    }));
  });

  return {
    filter,
    onGraphicDesignToggle,
    onDataCheckToggle,
    onDesignApproveToggle,
    onBigFormatPrintToggle,
    onSmallFormatPrintToggle,
    onShappedPrePlotterToggle,
    onWithoutAsaprintToggle,
    onTodayToggle,
    onTomorrowToggle,
  };
}

interface OrderReceived {
  expeditionAt?: string;
  assembyAt?: string;
  approvedAt?: string;
  moneyAddressName?: string;
  phase: string;
}

interface Task {
  product?: {
    phase?: {
      id: string;
      groupId: string;
    };
    orderReceived?: OrderReceived;
  };
  orderReceived?: OrderReceived;
}

export function filterTasks<T extends Task>(
  tasks: T[],
  filter: Filter,
  { checkApprovedAt = true }: { checkApprovedAt?: boolean } = {},
): T[] {
  return tasks.filter((t) => {
    return (
      (!filter.today ||
        isScheduledToday(t.orderReceived ?? t.product.orderReceived, {
          checkApprovedAt,
          checkInspectionAt: t.product?.phase?.id === Phases.INSPECTION,
          checkExpeditionAt: (t.orderReceived ?? t.product.orderReceived).phase !== OrderReceivedPhase.ASSEMBLY,
        })) &&
      (!filter.tomorrow ||
        isScheduledTomorrow(t.orderReceived ?? t.product.orderReceived, {
          checkApprovedAt,
          checkInspectionAt: t.product?.phase?.id === Phases.INSPECTION,
          checkExpeditionAt: (t.orderReceived ?? t.product.orderReceived).phase !== OrderReceivedPhase.ASSEMBLY,
        })) &&
      (!filter.withoutAsaprint ||
        (t.orderReceived || t.product.orderReceived).moneyAddressName?.trim() !== 'ASAPRINT TRADE s.r.o.') &&
      (Object.keys(filter).every(
        (k: keyof Filter) => !filter[k] || k === 'today' || k === 'tomorrow' || k === 'withoutAsaprint',
      ) ||
        (filter.graphicDesign &&
          (t.product?.phase?.id === Phases.GRAPHIC_DESIGN || t.product?.phase?.groupId === PhaseGroups.YORKIE)) ||
        (filter.dataCheck && t.product?.phase?.id === Phases.DATA_CHECK) ||
        (filter.designApprove && t.product?.phase?.id === Phases.DESIGN_APPROVE) ||
        (filter.smallFormatPrint && t.product?.phase?.id === Phases.DIGITAL_SMALL_FORMAT_PRINT) ||
        (filter.bigFormatPrint && t.product?.phase?.id === Phases.DIGITAL_BIG_FORMAT_PRINT) ||
        (filter.shapedPrePlotter && t.product?.phase?.id === Phases.SHAPED_PRE_PLOTTER))
    );
  });
}

export function shouldFilter(filter: Filter): boolean {
  return Object.keys(filter).some((k) => filter[k]);
}
