import KanbanColumn from '@asaprint/asap/components/KanbanColumn.js';
import { Task } from '@asaprint/asap/components/TaskCard.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { scrollbar } from '@engined/client/styles/mixins.js';
import { DragDropContext, DragDropContextProps, Droppable } from '@hello-pangea/dnd';
import { Box, SxProps } from '@mui/material';
import React, { useState } from 'react';

interface User {
  id: string;
  username: string;
  firstName?: string;
  lastName?: string;
  tasks?: Task[];
}

interface OwnProps {
  sx?: SxProps;
  users: User[];
  deadlineField?: 'approvedAt';
  startedField: 'productionStartedAt' | 'graphicDesignStartedAt';

  onTaskMove?(task: Task, userId: string, position: number);
  onColumnMove?(userId: string, position: number);
}

type Props = OwnProps;

const KanbanBoard: React.FunctionComponent<Props> = ({
  sx,
  users,
  onTaskMove,
  onColumnMove,
  deadlineField,
  startedField,
}) => {
  const [opened, setOpened] = useState<string[]>([]);
  const [draggingTask, setDraggingTask] = useState<Task>(null);

  const onToggle = useEventCallback((tasksIds: string[]) => {
    setOpened((s) => {
      if (tasksIds.every((t) => s.includes(t))) {
        return s.filter((i) => !tasksIds.includes(i));
      } else {
        return [...s.filter((i) => !tasksIds.includes(i)), ...tasksIds];
      }
    });
  });

  const onToggleAll = useEventCallback((userId: string) => {
    onToggle(users.find((u) => u.id === userId).tasks.map((t) => t.id));
  });

  const onDragStart = useEventCallback<DragDropContextProps['onDragStart']>(({ source, draggableId, type }) => {
    if (type === 'TASK') {
      setDraggingTask(users.find((u) => u.id === source.droppableId).tasks.find((t) => t.id === draggableId));
    }
  });

  const onDragEnd = useEventCallback<DragDropContextProps['onDragEnd']>(
    ({ source, destination, draggableId, type }) => {
      // Drop outside of droppable
      if (!destination) {
        return;
      }

      // Nothing has moved
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      if (type === 'COLUMN') {
        onColumnMove(draggableId, destination.index);
        return;
      }

      setDraggingTask(null);

      const sourceUser = users.find((u) => u.id === source.droppableId);
      const task = sourceUser.tasks.find((t) => t.id === draggableId);
      if (onTaskMove) {
        onTaskMove(task, destination.droppableId, destination.index);
      }
    },
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: { xs: 'auto', md: 'calc(100vh - 48px - 41px - 16px)' },
          ...scrollbar,
          ...sx,
        }}
      >
        <Box sx={{ minWidth: users.length * 375, mb: 2 }}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal" isDropDisabled={!onColumnMove}>
            {(provided) => (
              <Box display="flex" ref={provided.innerRef} {...provided.droppableProps}>
                {users.map((user, index) => (
                  <KanbanColumn
                    sx={{ flex: '0 0 367px', mr: 2, '&:last-child': { marginRight: 0 } }}
                    key={user.id}
                    user={user}
                    index={index}
                    tasks={user.tasks}
                    opened={opened}
                    onToggle={onToggle}
                    onToggleAll={onToggleAll}
                    allOpened={user.tasks.length > 0 && user.tasks.every((t) => opened.includes(t.id))}
                    draggingTask={draggingTask}
                    disableTaskMove={!onTaskMove}
                    disableColumnMove={!onColumnMove}
                    deadlineField={deadlineField}
                    startedField={startedField}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
      </Box>
    </DragDropContext>
  );
};

KanbanBoard.displayName = 'KanbanBoard';

export default KanbanBoard;
