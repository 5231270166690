import { ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import { green, red } from '@asaprint/asap/theme.js';
import { DeliveryTypes } from '@asaprint/common/constants/DeliveryType.js';
import {
  ORDER_RECEIVED_PHASE_NAMES,
  OrderReceivedStatus,
  phaseSx as orderReceivedPhaseSx,
  statusSx as orderReceivedStatusSx,
} from '@asaprint/common/constants/OrderReceived.js';
import { Phases } from '@asaprint/common/constants/Phase.js';
import {
  PRODUCT_STATUS_NAMES,
  ProductStatus,
  statusSx as productStatusSx,
} from '@asaprint/common/constants/Product.js';
import { TaskType } from '@asaprint/common/constants/Task.js';
import { blink } from '@asaprint/common/helpers/animations.js';
import { isAsaprintDelivery, isAssemblyDelivery, isCourierDelivery } from '@asaprint/common/helpers/DeliveryType.js';
import FormatDate from '@engined/client/components/FormatDate.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { url } from '@engined/core/services/routes.js';
import { faAngleDown, faAngleUp, faBox, faShippingFast, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from '@hello-pangea/dnd';
import { Box, Button, styled, SxProps } from '@mui/material';
import { differenceInBusinessDays, differenceInCalendarDays, differenceInHours, format } from 'date-fns';
import sk from 'date-fns/locale/sk/index.js';
import { upperFirst } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface OrderReceived {
  id: string;
  updatedAt: string;
  expeditionAt?: string;
  assemblyAt?: string;
  assemblyStartedAt?: string;
  approvedAt?: string;
  inspectionAt?: string;
  moneyAddressName?: string;
  moneyName?: string;
  moneyNumber?: string;
  phase: string;
  moneyDeliveryTypeId?: string;
}

export interface Product {
  id: string;
  updatedAt: string;
  name: string;
  position: number;
  phase?: {
    id: string;
    name: string;
    color: string;
    groupId: string;
  };
  status: string;
  orderReceived: OrderReceived;
  productionStartedAt?: string;
  graphicDesignStartedAt?: string;
}

export interface Task {
  id: string;
  type: TaskType;
  orderReceived?: OrderReceived;
  product?: Product;
  userId: string;
  position: number;
  activeTimeEntry?: {
    id: string;
    updatedAt: string;
    startAt: string;
  };
}

interface OwnProps {
  sx?: SxProps;
  index: number;
  task: Task;
  isOpen?: boolean;
  disableDrag: boolean;
  deadlineField?: 'approvedAt';
  startedField: 'productionStartedAt' | 'graphicDesignStartedAt';

  onToggle?(id: string, event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

const taskCardContainerSx: SxProps = {
  backgroundColor: '#f5f5f5',
  p: 2,
  borderRadius: '4px',
  transition: 'transform .1s',
  mx: 2,
};

const TaskCard: React.FunctionComponent<Props> = ({ sx, index, disableDrag, ...rest }) => {
  if (disableDrag) {
    return (
      <Box sx={sx}>
        <Box sx={taskCardContainerSx}>
          <TaskCardInner {...rest} />
        </Box>
      </Box>
    );
  }

  return (
    <Draggable draggableId={rest.task.id} index={index} isDragDisabled={disableDrag}>
      {(provided, snapshot) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={sx}>
          <Box
            sx={{
              ...taskCardContainerSx,
              transform: snapshot.isDragging && !snapshot.isDropAnimating ? 'rotateZ(10deg)' : undefined,
            }}
          >
            <TaskCardInner {...rest} />
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

TaskCard.displayName = 'TaskCard';

const Badge = styled('span')(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'center',
  borderRadius: '1em',
  padding: theme.spacing(1, 2),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  '&:last-child': {
    marginRight: 0,
  },
}));

export default React.memo<Props>(TaskCard);

const TaskCardHeader = styled('a')(({ theme }) => ({
  color: 'inherit',
  position: 'relative',
  display: 'block',
  userSelect: 'none',
  textDecoration: 'none',
  WebkitUserDrag: 'none',
}));

const TaskCardInner: React.FunctionComponent<Omit<Props, 'index' | 'className' | 'disableDrag'>> = ({
  task,
  isOpen,
  onToggle,
  deadlineField,
  startedField,
}) => {
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const realOpen = isOpen === undefined ? open : isOpen;

  const product = task.product;
  const orderReceived = task.product?.orderReceived || task.orderReceived;

  const onToggleCallback = useEventCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpen((s) => !s);
    onToggle(task.id, event);
  });

  const expeditionLeft = orderReceived.expeditionAt
    ? differenceInCalendarDays(new Date(orderReceived.expeditionAt), new Date())
    : null;

  const inspectionLeft = orderReceived.inspectionAt
    ? differenceInCalendarDays(new Date(orderReceived.inspectionAt), new Date())
    : null;

  const assemblyLeft = orderReceived.assemblyAt
    ? differenceInCalendarDays(new Date(orderReceived.assemblyAt), new Date())
    : null;

  const currentHour = Math.ceil(Date.now() / (60 * 60 * 1000)) * 60 * 60 * 1000;
  const deadline = useMemo(() => {
    const now = currentHour;
    const fieldValue = deadlineField
      ? orderReceived[deadlineField]
      : task.product?.phase?.id === Phases.INSPECTION
        ? orderReceived.inspectionAt
        : task.type === TaskType.Assembly
          ? orderReceived.assemblyAt
          : orderReceived.expeditionAt;
    if (!fieldValue) {
      return null;
    }

    const dest = new Date(fieldValue);
    const hours = differenceInHours(dest, now);
    const days = differenceInCalendarDays(dest, now);

    const dayOfWeek = upperFirst(format(dest, 'cccccc', { locale: sk }));
    const time = format(dest, 'HH:mm', { locale: sk });
    const datum = format(dest, 'dd.MM.', { locale: sk });

    return {
      negative: hours < 0,
      today: days === 0,
      value:
        days > 6 || days < 0 ? `${dayOfWeek} ${datum} ${time}` : days === 0 ? `Dnes ${time}` : `${dayOfWeek} ${time}`,
    };
  }, [task, orderReceived, deadlineField, currentHour]);

  const age = product?.[startedField]
    ? differenceInBusinessDays(new Date(), new Date(product[startedField]))
    : task.type === TaskType.Assembly && orderReceived.assemblyStartedAt
      ? differenceInBusinessDays(new Date(), new Date(orderReceived.assemblyStartedAt))
      : null;

  return (
    <>
      <TaskCardHeader onClick={onToggleCallback}>
        <Box position="relative">
          <Box fontWeight="700">
            {isCourierDelivery(orderReceived.moneyDeliveryTypeId as DeliveryTypes) ? (
              <FontAwesomeIcon icon={faBox} />
            ) : isAssemblyDelivery(orderReceived.moneyDeliveryTypeId as DeliveryTypes) ? (
              <FontAwesomeIcon icon={faWrench} />
            ) : isAsaprintDelivery(orderReceived.moneyDeliveryTypeId as DeliveryTypes) ? (
              <FontAwesomeIcon icon={faShippingFast} />
            ) : null}{' '}
            {product?.name || orderReceived.moneyName}
          </Box>
          <Box>{orderReceived.moneyAddressName}</Box>
          <Box position="absolute" top="40%" right="10px">
            <FontAwesomeIcon icon={realOpen ? faAngleUp : faAngleDown} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {task.type === TaskType.Product ? (
              <>
                {product.phase ? (
                  <Badge
                    sx={{
                      backgroundColor: `#${product.phase.color}`,
                      color: (theme) => theme.palette.getContrastText(`#${product.phase.color}`),
                    }}
                  >
                    {product.phase.name}
                  </Badge>
                ) : (
                  <Badge sx={{ backgroundColor: '#ccc' }}>Bez fázy</Badge>
                )}
                {product.status !== ProductStatus.OPEN && (
                  <Badge sx={[productStatusSx[product.status], !!task.activeTimeEntry && productStatusSx.active]}>
                    {PRODUCT_STATUS_NAMES[product.status]}
                  </Badge>
                )}
              </>
            ) : [TaskType.Assembly, TaskType.Packaging, TaskType.Delivery, TaskType.Expedition].includes(task.type) ? (
              <>
                <Badge sx={orderReceivedPhaseSx[orderReceived.phase]}>
                  {ORDER_RECEIVED_PHASE_NAMES[orderReceived.phase]}
                </Badge>
                {task.activeTimeEntry && (
                  <Badge sx={[orderReceivedStatusSx[OrderReceivedStatus.PROCESSING], orderReceivedStatusSx.active]}>
                    Prebieha
                  </Badge>
                )}
              </>
            ) : null}
            {deadline && (
              <Badge
                sx={{
                  backgroundColor: deadline.today ? '#ed7621' : deadline.negative ? '#f71919' : '#f8ac59',
                  color: '#fff',
                }}
              >
                {deadline.value}
              </Badge>
            )}
          </Box>
          <Box>
            {age > 0 && (
              <Badge
                sx={{
                  backgroundColor: age > 10 ? '#f71919' : age > 5 ? '#f71919' : age > 3 ? '#333f4f' : '#aeaeae',
                  color: '#fff',
                  animation: age > 10 ? `${blink} 1s steps(5, start) infinite` : undefined,
                }}
              >
                {age}
              </Badge>
            )}
          </Box>
        </Box>
      </TaskCardHeader>
      {realOpen && (
        <Box mt={2} pt={2} borderTop="1px solid #e7eaec">
          <Box fontWeight="bold">
            {product ? `${product.orderReceived.moneyNumber}-${product.position}` : orderReceived.moneyNumber}
          </Box>
          <Box component="table" width="100%">
            <tbody>
              {product?.phase.id === Phases.INSPECTION ? (
                <tr>
                  <DetailCell>Obhliadka:</DetailCell>
                  <DetailCell>
                    {orderReceived.inspectionAt ? (
                      <FormatDate date={orderReceived.inspectionAt} format="dd.MM.yyy" />
                    ) : (
                      '-'
                    )}
                  </DetailCell>
                  <DetailCell>
                    {orderReceived.inspectionAt ? <FormatDate date={orderReceived.inspectionAt} format="HH:mm" /> : '-'}
                  </DetailCell>
                  <DetailCell>
                    <DaysLeft count={inspectionLeft}>{inspectionLeft !== null ? inspectionLeft : '-'}</DaysLeft>
                  </DetailCell>
                </tr>
              ) : null}
              <tr>
                <DetailCell>Vyhotovenie:</DetailCell>
                <DetailCell>
                  {orderReceived.expeditionAt ? (
                    <FormatDate date={orderReceived.expeditionAt} format="dd.MM.yyy" />
                  ) : (
                    '-'
                  )}
                </DetailCell>
                <DetailCell>
                  {orderReceived.expeditionAt ? <FormatDate date={orderReceived.expeditionAt} format="HH:mm" /> : '-'}
                </DetailCell>
                <DetailCell>
                  <DaysLeft count={expeditionLeft}>{expeditionLeft !== null ? expeditionLeft : '-'}</DaysLeft>
                </DetailCell>
              </tr>
              <tr>
                <DetailCell>Montáž:</DetailCell>
                <DetailCell>
                  {orderReceived.assemblyAt ? format(new Date(orderReceived.assemblyAt), 'dd.MM.yyyy') : '-'}
                </DetailCell>
                <DetailCell>
                  {orderReceived.assemblyAt ? format(new Date(orderReceived.assemblyAt), 'HH:mm') : '-'}
                </DetailCell>
                <DetailCell>
                  <DaysLeft count={assemblyLeft}>{assemblyLeft !== null ? assemblyLeft : '-'}</DaysLeft>
                </DetailCell>
              </tr>
            </tbody>
          </Box>

          <Box mt={1}>
            <Button
              component={RouterLink}
              size="small"
              variant="outlined"
              to={
                url(ORDERS_RECEIVED_SHOW_ROUTE, { id: orderReceived.id }) +
                (task.type === TaskType.Product ? `#product-${product.id}` : '')
              }
            >
              Zobraziť {task.type === TaskType.Product ? 'produkt' : 'objednávku'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

TaskCardInner.displayName = 'TaskCardInner';

const DetailCell = styled('td')(({ theme }) => ({
  pr: 1,
  '&:last-child': {
    pr: 0,
  },
}));

const DaysLeft = styled('span', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'count' && propName !== 'sx',
})<{ count: number | undefined }>(({ theme, count }) => ({
  display: 'inline-flex',
  width: 25,
  height: 25,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: !count && count !== 0 ? undefined : theme.palette.common.white,
  backgroundColor: count > 0 ? green : count < 0 ? red : count === 0 ? '#adb5bd' : undefined,
}));
