interface Task {
  __typename?: 'Task';
  id: string;
  position: number;
  userId: string;
  updatedAt?: string;
  activeTimeEntry?: {
    id: string;
    updatedAt: string;
    startAt: string;
  };
}

interface User<TTask extends Task> {
  __typename?: 'User';
  id: string;
  tasks?: TTask[];
}

export function onMoveHelper<TTask extends Task>(task: TTask, userId: string, position: number, users: User<TTask>[]) {
  // Get task.position for task on given position
  const user = users.find((u) => u.id === userId);
  // TODO: Maybe hoist finalPosition calculation
  //  - e.g. when we want to allow D&D for processing
  //    and not processing tasks
  let finalPosition = position;
  if (user) {
    const notProcessingTasks = user.tasks.filter((t) => !t.activeTimeEntry);
    const tasksLength = notProcessingTasks.length;
    if (tasksLength) {
      finalPosition =
        tasksLength <= position
          ? notProcessingTasks[tasksLength - 1].position + 1
          : notProcessingTasks[position].position;
    }
  }
  if (task.userId === userId && task.position === finalPosition) {
    return;
  }

  const currentUser = users.find((u) => u.id === task.userId);
  const updatedTasks: TTask[] = [
    {
      ...task,
      position: finalPosition,
      userId,
    },
  ].concat(
    currentUser === user
      ? task.position - finalPosition > 0
        ? user.tasks // Moving UP
            .filter((t) => t.position >= finalPosition && t.position < task.position)
            .map((t) => ({
              ...t,
              position: t.position + 1,
            }))
        : user.tasks // Moving down
            .filter((t) => t.position > task.position && t.position <= finalPosition)
            .map((t) => ({
              ...t,
              position: t.position - 1,
            }))
      : currentUser.tasks
          .filter((t) => t.position > task.position)
          .map((t) => ({
            ...t,
            position: t.position - 1,
          }))
          .concat(
            user.tasks
              .filter((t) => t.position >= finalPosition)
              .map((t) => ({
                ...t,
                position: t.position + 1,
              })),
          ),
  );

  return {
    position: finalPosition,
    tasks: updatedTasks,
  };
}
