import PagePaper from '@asaprint/asap/components/PagePaper.js';
import TaskCard, { Task } from '@asaprint/asap/components/TaskCard.js';
import { TaskType } from '@asaprint/common/constants/Task.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { alpha, Box, Button, SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';

interface User {
  id: string;
  username: string;
  firstName?: string;
  lastName?: string;
}

interface OwnProps {
  sx?: SxProps;
  user: User;
  tasks: Task[];
  opened: string[];
  allOpened: boolean;
  draggingTask: Task;
  disableTaskMove: boolean;
  disableColumnMove: boolean;
  index: number;
  deadlineField?: 'approvedAt';
  startedField: 'productionStartedAt' | 'graphicDesignStartedAt';

  onToggle(taskIds: string[]);

  onToggleAll(userId: string);
}

type Props = OwnProps;

const cardSx: SxProps = { mb: 2, '&:last-child': { mb: 0 } };

const KanbanColumn: React.FunctionComponent<Props> = ({
  sx,
  user,
  tasks,
  opened,
  allOpened,
  draggingTask,
  disableTaskMove,
  disableColumnMove,
  onToggle,
  onToggleAll,
  index,
  deadlineField,
  startedField,
}) => {
  const onToggleAllCallback = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onToggleAll(user.id);
  });

  const onToggleTask = useEventCallback((taskId: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onToggle([taskId]);
  });

  const canDrop = useMemo(() => {
    if (!draggingTask) {
      return false;
    }

    if (draggingTask.userId === user.id) {
      return true;
    }

    if (draggingTask.activeTimeEntry) {
      return false;
    }

    if (draggingTask.type === TaskType.Product) {
      return !tasks
        .filter((t) => t.type === TaskType.Product)
        .map((t) => t.product.id)
        .includes(draggingTask.product.id);
    } else if (draggingTask.type === TaskType.Assembly) {
      return !tasks
        .filter((t) => t.type === TaskType.Assembly)
        .map((t) => t.orderReceived.id)
        .includes(draggingTask.orderReceived.id);
    } else if (draggingTask.type === TaskType.Packaging) {
      return !tasks
        .filter((t) => t.type === TaskType.Packaging)
        .map((t) => t.orderReceived.id)
        .includes(draggingTask.orderReceived.id);
    } else if (draggingTask.type === TaskType.Delivery) {
      return !tasks
        .filter((t) => t.type === TaskType.Delivery)
        .map((t) => t.orderReceived.id)
        .includes(draggingTask.orderReceived.id);
    } else if (draggingTask.type === TaskType.Expedition) {
      return !tasks
        .filter((t) => t.type === TaskType.Expedition)
        .map((t) => t.orderReceived.id)
        .includes(draggingTask.orderReceived.id);
    }
  }, [draggingTask, tasks, user]);

  const processingTasks = tasks.filter((t) => t.activeTimeEntry);
  const notProcessingTasks = tasks.filter((t) => !t.activeTimeEntry);

  return (
    <Draggable draggableId={user.id} index={index} isDragDisabled={disableColumnMove}>
      {(provided, snapshot) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} sx={sx}>
          <PagePaper
            key={user.id}
            title={
              <Box display="flex" justifyContent="space-between" {...provided.dragHandleProps}>
                <Box>{displayName(user)}</Box>
                <Box>
                  <Button
                    size="small"
                    variant={!allOpened ? 'outlined' : 'contained'}
                    onClick={onToggleAllCallback}
                    disableElevation
                  >
                    Detaily
                  </Button>
                </Box>
              </Box>
            }
          >
            <Box py={4} borderBottom="1px solid #e7eaec">
              {processingTasks.map((task, index) => (
                <TaskCard
                  key={task.id}
                  index={index}
                  task={task}
                  isOpen={opened.includes(task.id)}
                  onToggle={onToggleTask}
                  disableDrag={true}
                  deadlineField={deadlineField}
                  startedField={startedField}
                  sx={cardSx}
                />
              ))}
              {processingTasks.length === 0 && <Box textAlign="center">Momentálne nepracuje na žiadnom produkte</Box>}
            </Box>
            <Droppable droppableId={user.id} isDropDisabled={!canDrop || disableTaskMove} type="TASK">
              {(provided, snapshot) => (
                <Box
                  pt={4}
                  pb={5}
                  minHeight={100}
                  sx={{
                    transition: 'background-color .2s ease',
                    background: (theme: Theme) =>
                      snapshot.isDraggingOver ? alpha(theme.palette.primary.main, 0.3) : undefined,
                  }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {notProcessingTasks.map((task, index) => (
                    <TaskCard
                      key={task.id}
                      index={index}
                      task={task}
                      isOpen={opened.includes(task.id)}
                      onToggle={onToggleTask}
                      disableDrag={disableTaskMove}
                      deadlineField={deadlineField}
                      startedField={startedField}
                      sx={cardSx}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </PagePaper>
        </Box>
      )}
    </Draggable>
  );
};

KanbanColumn.displayName = 'KanbanColumn';

export default React.memo<Props>(KanbanColumn);
